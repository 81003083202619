import axios from "@/libs/axios";
import {isUserLoggedIn} from "@/auth/utils";

const state = {
    user: null,
    user_onboarding: [],
    user_onboarding_percent: 0,
}

const mutations = {
    SET_USER: (state, user) => {
        state.user = user;
    },
    SET_USER_ONBOARDING: (state, user_onboarding) => {
        state.user_onboarding = user_onboarding;

        // let itemsCompleted = 0;
        //
        // console.debug(state.user_onboarding.length)
        // state.user_onboarding.forEach((item, index) => {
        //     if (item === true) itemsCompleted++;
        // })
        //
        // state.user_onboarding_in_percent = itemsCompleted > 0 ? itemsCompleted : state.user_onboarding.length/itemsCompleted
    },
    SET_USER_ONBOARDING_PERCENT: (state, user_onboarding_percent) => {
        state.user_onboarding_percent = user_onboarding_percent;

        // let itemsCompleted = 0;
        //
        // console.debug(state.user_onboarding.length)
        // state.user_onboarding.forEach((item, index) => {
        //     if (item === true) itemsCompleted++;
        // })
        //
        // state.user_onboarding_in_percent = itemsCompleted > 0 ? itemsCompleted : state.user_onboarding.length/itemsCompleted
    },
}

const actions = {
    GET_USER: (context) => {
        const isLoggedIn = isUserLoggedIn()

        if (isLoggedIn) {
            return axios.get('/experts/profile').then(response => {
                context.commit('SET_USER', response.data);
            })
        }
    },
    GET_USER_ONBOARDING: (context) => {
        return axios.get('/experts/onboarding').then(response => {
            let itemsCompleted = 0;
            response.data.forEach((item, index) => {
                item.hover = false

                if (item.done === true) itemsCompleted++;
            })

            let percent = itemsCompleted > 0 ? itemsCompleted : state.user_onboarding.length/itemsCompleted
            context.commit('SET_USER_ONBOARDING_PERCENT', percent);
            context.commit('SET_USER_ONBOARDING', response.data);
        })
    },
    updateOnBoardingProcessData: (context) => {
        this.GET_USER_ONBOARDING(context)
    }
}

const getters = {
    USER: state => {
        return state.user
    },
    USER_ONBOARDING: state => {
        return state.user_onboarding
    },
    USER_ONBOARDING_IN_PERCENT: state => {
        return state.user_onboarding_in_percent
    },
}

export default {
    state, getters, mutations, actions
}
