export default [


  //Videosnippets
  {
    path: '/videosnippets/list',
    name: 'apps-videosnippets-list',
    component: () => import('@/views/videosnippets/VideoSnippetsList.vue'),
    meta: {
      breadcrumb: [{
        text: 'Video Nuggets List',
        to: '/apps/videosnippets/list',
        active: true
      }, ],
      pageTitle: 'Video Nuggets List',
      navActiveLink: 'apps-videosnippets-list',
    },
  },
  {
    path: '/videosnippets/:id/edit',
    name: 'apps-videosnippets-edit',
    component: () => import('@/views/videosnippets/VideosnippetsEdit.vue'),
    meta: {
      breadcrumb: [{
        text: 'Video Nuggets List',
        to: '/videosnippets/list',
      },
        {
          text: 'Edit Video Nugget',
          to: '/apps/videosnippets/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Video Nugget',
      navActiveLink: 'apps-videosnippets-list',
    },
  },

  {
    path: '/videosnippets/new',
    name: 'apps-videosnippets-new',
    component: () => import('@/views/videosnippets/VideosnippetsAdd.vue'),
    meta: {
      breadcrumb: [{
        text: 'Video Nuggets List',
        to: '/videosnippets/list'
      },
        {
          text: 'Add Video Nugget',
          to: '/apps/videosnippets/new',
          active: true
        },
      ],
      pageTitle: 'Add Video Nugget',
      navActiveLink: 'apps-videosnippets-list',
    },
  },





]
