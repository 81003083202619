export default [

//Future Bookings
  {
    path: '/bookings/futurebookings/list',
    name: 'apps-bookings-futurebookings-list',
    component: () => import('@/views/bookings/futurebookings/FutureBookingsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Upcoming Bookings',
          to: '/apps/bookings/list',
          active: true
        },
      ],
      pageTitle: 'Upcoming Bookings',
      navActiveLink: 'apps-bookings-futurebookings-list',
    },
  },

  {
    path: '/bookings/:bookingid/details',
    name: 'apps-bookings-futurebookings-details',
    component: () => import('@/views/bookings/futurebookings/FutureBookingsDetails.vue'),
    meta: {
      layout: 'event-room',
    },
  },


  //Past Bookings
  {
    path: '/bookings/pastbookings/list',
    name: 'apps-bookings-pastbookings-list',
    component: () => import('@/views/bookings/pastbookings/PastBookingsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Past Bookings',
          to: '/apps/bookings/list',
          active: true
        },
      ],
      pageTitle: 'Past Bookings',
      navActiveLink: 'apps-bookings-pastbookings-list',
    },
  },

  {
    path: '/bookings/cancelledbookings/list',
    name: 'apps-bookings-cancelledbookings-list',
    component: () => import('@/views/bookings/cancelledbookings/CancelledBookingsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Cancelled Bookings',
          to: '/apps/bookings/list',
          active: true
        },
      ],
      pageTitle: 'Cancelled Bookings',
      navActiveLink: 'apps-bookings-cancelledbookings-list',
    },
  },


  {
    path: '/bookings/pastbookings/details',
    name: 'apps-bookings-pastbookings-details',
    component: () => import('@/views/bookings/pastbookings/PastBookingsDetails.vue'),
    meta: {
      breadcrumb: [{
        text: 'Bookings List',
        to: '/apps/bookings/list',
        active: true
      },],
      pageTitle: 'Past Booking Details',
      navActiveLink: 'apps-bookings-upcomingbookings-list',
    },
  },


  //Request Bookings
  {
    path: '/bookings/requestbookings/list',
    name: 'apps-bookings-requestbookings-list',
    component: () => import('@/views/bookings/requestbookings/RequestBookingsList.vue'),
    meta: {
      breadcrumb: [{
        text: 'Requests Bookings List',
        to: '/apps/requestbookings/list',
        active: true
      },],
      pageTitle: 'Requests Bookings List',
      navActiveLink: 'apps-bookings-requestbookings-list',
    },
  },

  {
    path: '/bookings/requestbookings/details',
    name: 'apps-bookings-requestbookings-details',
    component: () => import('@/views/bookings/requestbookings/RequestBookingsDetails.vue'),
    meta: {
      breadcrumb: [{
        text: 'Requests Bookings Details',
        to: '/apps/requestbookings/list',
        active: true
      },],
      pageTitle: 'Requests Bookings Details',
      navActiveLink: 'apps-bookings-requestbookings-list',
    },
  },

]
