import axios from "@/libs/axios";

const state = {
    events: null,
    latest:[],
    pagination: {}
}

const mutations = {
    SET_EVENTS: (state, events) => {
        state.events = events
    },
    SET_PAGINATION: (state, pagination) => {
        state.pagination = pagination;
    },
}

const actions = {
    GET_EVENTS: (context, payload) => {
        let url = `/events`
        return axios.get(url, { params: { page: payload.page, search: payload.searchParams }}).then(response => {
            if (payload.page == 1) {
                state.latest = []
            }
            state.latest.push(...response.data.items.data)
            context.commit('SET_EVENTS', state.latest);
            let pagination = {
                'current_page': response.data.items.current_page,
                'last_page': response.data.items.last_page
            }
            context.commit('SET_PAGINATION', pagination);
        })
    },
}

const getters = {
    EVENTS: state => {
            return state.events
    },
    EVENTS_PAGINATION: state => {
        return state.pagination;
    },
}

export default {
    state, getters, mutations, actions
}
