export default [
  //Booking Times
  {
    path: '/timemanagement/bookingtimes',
    name: 'apps-bookingtimes-list',
    component: () => import('@/views/bookingtimes/bookingtimes/BookingTimesList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Booking Times',
          to: '/timemanagement/bookingtimes',
          active: true
        },],
      pageTitle: 'Booking Times',
      navActiveLink: 'apps-bookingtimes-list',
    },
  },
  {
    path: '/timemanagement/blockedtimes',
    name: 'blockedtimes',
    component: () => import('@/views/bookingtimes/blockingtimes/BlockedTimesList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Blocked Times',
          to: '/timemanagement/blockedtimes',
          active: true
        },],
      pageTitle: 'Blocked Times',
      navActiveLink: 'blockedtimest',
    },
  },
  {
    path: '/timemanagement/bookingtimes/:id/edit',
    name: 'apps-bookingtimes-edit',
    component: () => import('@/views/bookingtimes/bookingtimes/BookingTimesEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Booking Times',
          to: '/timemanagement/bookingtimes'
        },
        {
          text: 'Edit',
          to: '/timemanagement/bookingtimes/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Booking Time',
      navActiveLink: 'apps-bookingtimes-list',
    },
  },
  {
    path: '/timemanagement/bookingtimes/new',
    name: 'apps-bookingtimes-new',
    component: () => import('@/views/bookingtimes/bookingtimes/BookingTimesAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Booking Times',
          to: '/timemanagement/bookingtimes'
        },
        {
          text: 'Add',
          to: '/timemanagement/bookingtimes/new',
          active: true
        },
      ],
      pageTitle: 'Add Booking Time',
      navActiveLink: 'apps-bookingtimes-list',
    },
  },
  {
    path: '/timemanagement/blockedtime/new',
    name: 'blockedtime-new',
    component: () => import('@/views/bookingtimes/blockingtimes/BlockedTimesAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Blocked Times',
          to: '/timemanagement/blockedtimes'
        },
        {
          text: 'Add',
          to: '/timemanagement/bookingtimes/new',
          active: true
        },
      ],
      pageTitle: 'Add Blocked Time',
      navActiveLink: 'blockedtimes',
    },
  },
  {
    path: '/timemanagement/blockedtime/:id/edit',
    name: 'blockedtime-edit',
    component: () => import('@/views/bookingtimes/blockingtimes/BlockedTimesEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Blocked Times',
          to: '/timemanagement/blockedtimes'
        },
        {
          text: 'Edit',
          to: '/timemanagement/bookingtimes/id/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Blocked Time',
      navActiveLink: 'blockedtimes',
    },
  },
  {
    path: '/timemanagement/googlecalendar',
    name: 'timemanagement-googlecalendar',
    component: () => import('@/views/googlecalendar/GoogleCalendar'),
    meta: {
      breadcrumb: [
        {
          text: 'Google calendar integration',
          to: '/timemanagement/googlecalendar'
        }
      ],
      pageTitle: 'Google calendar integration',
      navActiveLink: 'timemanagement-googlecalendar',
    },
  }
]
