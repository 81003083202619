export default [

  //Events
  {
    path: '/events/list',
    name: 'apps-events-list',
    component: () => import('@/views/events/EventList/EventsList.vue'),
    meta: {
      breadcrumb: [{
        text: 'Events List',
        to: '/apps/events/list',
        active: true
      }, ],
      pageTitle: 'Events List',
      navActiveLink: 'apps-events-list',
    },
  },
  {
    path: '/events/:eventid/edit',
    name: 'apps-events-edit',
    component: () => import('@/views/events/EventsEdit.vue'),
    meta: {
      breadcrumb: [{
          text: 'Events List',
          to: '/events/list'
        },
        {
          text: 'Edit Event',
          to: '/apps/events/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Event',
      navActiveLink: 'apps-events-list',
    },
  },

  {
    path: '/events/new',
    name: 'apps-events-new',
    component: () => import('@/views/events/EventsAdd.vue'),
    meta: {
      breadcrumb: [{
          text: 'Events List',
          to: '/events/list'
        },
        {
          text: 'Add Event',
          to: '/apps/events/new',
          active: true
        },
      ],
      pageTitle: 'Add Event',
      navActiveLink: 'apps-events-list',
    },
  },

]
