import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import events from "@/store/events";
import videonuggets from "@/store/videonuggets";
import user from "@/store/user";
import blockedtimes from "@/store/blockedtimes";
import chat from "@/store/chat";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    blockedtimes,
    user,
    videonuggets,
    events,
    app,
    appConfig,
    verticalMenu,
    chat
  },
  strict: process.env.DEV,
})
