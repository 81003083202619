import axios from "@/libs/axios";

const state = {
  videoNuggets: [],
  latest: [],
  pagination: {}
}

const mutations = {
  SET_VIDEO_NUGGETS: (state, videoNuggets) => {
    state.videoNuggets = videoNuggets;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
}

const actions = {

  GET_VIDEO_NUGGETS: async (context, payload) => {
    await axios.get(`/videosnippets`, {params: {page: payload.page, search: payload.searchParams}})
        .then(response => {
          if (payload.page == 1) {
            state.latest = []
          }
          state.latest.push(...response.data.items.data)
          context.commit('SET_VIDEO_NUGGETS', state.latest);
          let pagination = {
            'current_page': response.data.items.current_page,
            'last_page': response.data.items.last_page
          }
          context.commit('SET_PAGINATION', pagination);
        })
  },
  RESET_VIDEO_NUGGETS: (context) => {
    state.videoNuggets = []
  }
}

const getters = {

  VIDEO_NUGGETS: state => {
    return state.videoNuggets;
  },
  VIDEO_NUGGETS_PAGINATION: state => {
    return state.pagination;
  },

}

export default {
  state, getters, mutations, actions
}
