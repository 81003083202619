export default [

  //Events
  {
    path: '/credits/list',
    name: 'credits-management',
    component: () => import('@/views/credits/CreditManagementList.vue'),
    meta: {
      breadcrumb: [{
        text: 'Credit Management',
        to: '/apps/credits/list',
        active: true
      }, ],
      pageTitle: 'Credit Management List',
      navActiveLink: 'credits-management',
    },
  },
  {
    path: '/credits/payout',
    name: 'credits-payout',
    component: () => import('@/views/credits/CreditPayout.vue'),
    meta: {
      breadcrumb: [{
        text: 'Credit Payout',
        to: '/apps/credits/payout',
        active: true
      }, ],
      pageTitle: 'Credit Payout',
      navActiveLink: 'credits-payout',
    },
  },




]
