import axios from "@/libs/axios";


const actions = {
    ADD_BLOCKED_TIME: (context, data) => {
        axios.post('blockedtimes', data)
    },

}



export default {
  actions
}
